import { createRouter, createWebHistory } from "vue-router";
const routes = [
  {
    path: "/",
    name: "Home",
    meta: {
      title: "首页",
    },
    component: () => import("@/views/Home.vue"),
  },
  {
    path: "/profiles",
    name: "Profiles",
    meta: {
      title: "公司简介",
    },
    component: () => import("@/views/Profiles.vue"),
  },
  {
    path: "/production",
    name: "Production",
    meta: {
      title: "药品信息(收费)",
    },
    component: () => import("@/views/Production.vue"),
    children: [
      {
        path: "",
        name: "ProductionList",
        meta: {
          title: "药品数据",
        },
        component: () => import("@/views/ProductionList.vue"),
      },
      {
        path: "detail",
        name: "ProductionDetail",
        meta: {
          title: "药品数据",
        },
        component: () => import("@/views/ProductionDetail.vue"),
      },
      {
        path: "detailInfo",
        name: "ProductionDetailInfo",
        meta: {
          title: "药品详情",
        },
        component: () => import("@/views/ProductionDetailInfo.vue"),
      }
    ],
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
    meta: {
      title: "联系我们",
    },
  },
  {
    path: "/register",
    name: "Register",
    meta: {
      title: "登录 / 注册",
    },
    component: () => import("@/views/Register.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
