<template>
  <header class="page-header">
    <div class="container">
      <a-row class="navbar" type="flex">
        <a-col class="navbar-brand">
          <a-row>
            <a-col class="navbar-item" :span="8">
              <a href="/" class="navbar-logo">
                <img :src="logo" alt="北京英芙麦迪科技有限公司"
              /></a>
            </a-col>
            <a-col>
              <a class="navbar-burger burger">
                <span class="navbar-small-icon"></span>
              </a>
            </a-col>
          </a-row>
        </a-col>
        <a-col class="navbar-menu" style="flex:1;">
          <a-menu mode="horizontal" :selectedKeys="[$route.path]">
            <template v-for="(item, i) in routeList" :key="i + 1">
              <a-menu-item v-model:key="item.link">
                <router-link :to="item.link">{{ item.title }}</router-link>
              </a-menu-item>
            </template>
          </a-menu>
        </a-col>
      </a-row>
    </div>
  </header>
</template>

<script>
/****************
 *@author: niubj
 *@date: 2021-11-24 14:21:55
 *@version: V1.0.0
 *@description: 头部数据
 *************************************************************************/
export default {
  name: "MainHeader",
  components: {},
  props: {},
  data() {
    return {
      logo: require("../assets/images/logo.png"),
      routeList: [],
      rmkScssUserName: localStorage.getItem("rmkScssUserName"),
    };
  },
  computed: {
    routerOpts() {
      return this.$router.options.routes;
    },
  },
  watch: {
    rmkScssUserName() {
      this.handlerRoutes();
    },
  },
  mounted() {
    this.handlerRoutes();
  },
  methods: {
    handlerRoutes() {
      let routesInit = [...this.routerOpts];
      let rmkScssUserName = localStorage.getItem("rmkScssUserName");
      for (let i = 0; i < routesInit.length; i++) {
        const { path, meta, name } = routesInit[i];
        this.routeList.push({
          link: path,
          title: meta.title,
          name,
        });
      }
      let menuOpts = JSON.parse(JSON.stringify(this.routeList));
      if (rmkScssUserName) {
        this.routeList = menuOpts.slice(0, 4);
      } else {
        this.routeList = [...menuOpts];
      }
    },
  },
};
</script>
<style lang="less" scoped>
.page-header {
  height: 72px;
}
.navbar-burger {
  display: none;
}
.navbar {
  justify-content: space-between;
}

.navbar-small-icon {
  display: inline-block;
  width: 48px;
  height: 48px;
  background: url("../assets/images/list view.png") no-repeat 100%;
}
:deep(.ant-menu-horizontal) {
  border-bottom: none;
  justify-content: flex-end;
  &:not(.ant-menu-dark) {
    .ant-menu-item {
      padding: 0 30px;
      font-size: 16px;
      &:hover {
        background-color: #f2f2f2;
        a {
          color: #022a75;
        }

        &::after {
          border-bottom: 2px solid #022a75;
        }
      }
    }
  }
  .ant-menu-item-selected {
    a {
      color: #022a75;
    }
    &::after {
      border-color: #022a75;
    }
  }
}
</style>
