<template>
  <div id="app">
    <a-config-provider :locale="zhCN" :getPopupContainer="getPopupContainer">
      <a-layout class="main-layout">
        <!-- 头部 -->
        <a-layout-header>
          <main-header></main-header>
        </a-layout-header>
        <!-- 内容 -->
        <a-layout-content>
          <router-view></router-view>
        </a-layout-content>
        <!-- 底部信息栏 -->
        <a-layout-footer>
          <main-footer></main-footer>
        </a-layout-footer>
      </a-layout>
    </a-config-provider>
  </div>
</template>

<script>
/****************
 *@author: niubj
 *@date: 2021-11-24 14:05:55
 *@version: V1.0.0
 *@description: 功能描述
 *************************************************************************/

import MainHeader from "@/components/Header.vue";
import MainFooter from "@/components/Footer.vue";
export default {
  name: "App",
  components: {
    MainHeader,
    MainFooter,
  },
  props: {},
  data() {
    return {};
  },

  computed: {},
  watch: {},

  methods: {},
};
</script>
<style lang="less" scoped>
:deep(.ant-layout-header) {
  height: 72px;
  background-color: #fff;
}
:deep(.ant-layout-footer) {
  padding: 0;
}
</style>
