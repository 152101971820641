<template>
  <footer class="main-footer">
    <div class="main-footer-wrapper">
      <div class="container">
        <div class="footer-content">
          <div class="list-box">
            <a-row type="flex" class="info-list common-list">
              <a-col v-for="(item, i) in infoList" :key="i">
                <i :class="['css-sprite', `icon-${i}`]"></i>
                <label>{{ item.label }}</label>
                <span>{{ item.value }}</span>
              </a-col>
            </a-row>
          </div>
          <div class="list-box">
            <a-row type="flex" class="page-list common-list">
              <a-col v-for="(item, i) in routeList" :key="i">
                <router-link :to="item.link">{{ item.title }}</router-link>
              </a-col>
            </a-row>
          </div>
          <div class="footer-bottom list-box">
            <p class="site-intro">
              Copyright © 北京英芙麦迪科技有限公司
            </p>
          </div>
          <div class="footer-beian">
            <a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank">京ICP备07502511号</a>
            <span>|</span>
            <a href="http://xxcx.yjj.beijing.gov.cn/eportal/ui?pageId=783954" target="_blank">（京）网药械信息备字（2022）第00010号</a>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
/****************
 *@author: niubj
 *@date: 2021-11-24 14:42:00
 *@version: V1.0.0
 *@description: 功能描述
 *************************************************************************/
export default {
  name: "MainFooter",
  components: {},
  props: {},
  data() {
    return {
      infoList: [
        {
          label: "服务热线：",
          value: "010-80429920",
        },
        {
          label: "地址：",
          value: "北京市顺义区竺园二街2号院8号楼",
        },
      ],
      routeList: [],
    };
  },
  computed: {
    routerOpts() {
      return this.$router.options.routes;
    },
  },
  watch: {},
  mounted() {
    this.handlerRoutes();
  },
  methods: {
    handlerRoutes() {
      let routesInit = [...this.routerOpts];
      for (let i = 0; i < routesInit.length; i++) {
        const { path, meta, name } = routesInit[i];
        if (name !== "Register") {
          this.routeList.push({
            link: path,
            title: meta.title,
          });
        }
      }
    },
  },
};
</script>
<style lang="less" scoped>
.main-footer {
  background-color: #fff;
  box-shadow: 0 -1px 4px gainsboro;
}
.main-footer-wrapper {
  padding: 2rem 1.5rem 1rem 1.5rem;
}
.container {
  padding-bottom: 24px;
}
.list-box {
  padding: 0.75rem;
}
.common-list {
  align-items: center;
  justify-content: space-between;
  color: #888;
  font-size: 14px;
  &.page-list {
    width: 50%;
    margin: auto;
    a {
      color: #888;
    }
  }
}
.css-sprite {
  display: inline-block;
  margin-right: 3px;
  width: 20px;
  height: 20px;
  background: url("../assets/images/css_sprites_icon.png") no-repeat;
  vertical-align: middle;
  &.icon-0 {
    background-position: -49px -49px;
  }
  &.icon-1 {
    width: 25px; 
    background-position: -8px -49px;
  }
}
.site-intro {
  text-align: center;
  text-indent: 0;
  color: #888;
  font-family: Microsoft YaHei;
  font-size: 14px;
  margin-top: 1rem;
}
.footer-beian{
  padding: 15px 0 0 ;
  text-align: center;
  a{
    color: #888;
  }
  span{
    padding: 0 10px;
    color: #888;
  }
}
</style>
