import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import "./style/common.less";
import {
  Layout,
  Row,
  Col,
  Menu,
  Carousel,
  List,
  Button,
  Form,
  Input,
  Tabs,
  ConfigProvider,
} from "ant-design-vue";
createApp(App)
  .use(router)
  .use(Layout)
  .use(Row)
  .use(Col)
  .use(Menu)
  .use(Carousel)
  .use(List)
  .use(Button)
  .use(Form)
  .use(Input)
  .use(Tabs)
  .use(ConfigProvider)
  .mount("#app");
